<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="">
       <div class="row">    
              <div class="col-md-12 col-12">
                 <div class="card-header">
                <h3 class="card-title"> {{name}} Order List </h3>
              </div>
              </div>
             
            </div> 
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <section class="col-lg-12 connectedSortable">
            <div class="">            
              <div class="">
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">
                   <div class="row">
<div class="col-md-12">
<div class="card card-primary cardpad">              
              <!-- /.card-header -->
<div class="card-body">

<table class="table">
  <tr>
    <th>Order Id</th>
    <th>Name</th>
    <th>Mobile</th>
    <th>Date</th>
    <th>Total Amount</th>
    <th v-if="this.app_type != '0'">Action</th>
  </tr>

  <tr v-for="user in orderList" :key="user.id">
    <td><router-link class="linkdark" :to="{path:'/vieworder/' + user.id}"> #{{ user.invoice_no }} </router-link></td>
    <td>{{ user.name }}</td>
    <td>{{ user.mobile_no }}</td>
    <td>{{ user.booking_date }}</td>
    <td>Rs. {{ user.paid_amount }}</td>
    <td v-if="this.app_type != '0'">
      <button v-if="user.order_status == 'Delivered'" type="button" class="btn btn-outline-danger btn-xs">{{user.order_status}}</button>
<button v-if="user.order_status == 'Pending'" type="button" class="btn btn-outline-success btn-xs">{{user.order_status}}</button>
    </td>
  </tr>
</table>



               
              </div>
              <!-- /.card-body -->
            </div>

        </div>
      </div>  

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {
   components:{
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar
      },
    data() {          
      return {
        company_id:this.$store.state.auth.user.data[0].company_id,
        app_type:this.$store.state.auth.user.data[0].app_type, 
        token: localStorage.token,
        quote: '',
        orderList: [],        
      }
    },   

     mounted() {         
	axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/invoice-list",  {
    user_id:this.$store.state.auth.user.data[0].id,
    page: '1',
    company_id: this.company_id,
    client_id: this.$route.params.id,
    search_data: '',
	})
      .then(res => {
        //alert(res.data.data)
        this.orderList = res.data.data.client_list;  
        this.name = res.data.data.client_list[0].name
        console.log(this.orderList)
      })
      .catch(error => {        
        console.log(error)
         // Manage errors if found any
      })
  },    

  };
</script>

<style>
  @import '../../assets/dist/css/custom.css'  
</style>